
export default class Anchor {

    /**
     * @private
     * @member {User}
     */
    _user;

    /**
     * @private
     * @member {Element}
     */
    _element;

    /**
     * @private
     * @member {URL}
     */
    _url;

    /**
     * @param {HTMLAnchorElement} htmlAnchorElement
     * @param {User} user
     */
    constructor(htmlAnchorElement, user) {

        this._url = this.__getUrlFromString(htmlAnchorElement.getAttribute("href"));

        if (!this._url) {
            return;
        }

        this._element = htmlAnchorElement;
        this._user = user;

        this._handleUserInfoQueryParam();
    }

    _handleUserInfoQueryParam() {
        const searchParams = this._url.searchParams;

        if ("true" !== searchParams.get("userInfo")) {
            return;
        }

        searchParams.delete("userInfo");
        searchParams.set("deviceInstanceIdHash", this._user.deviceInstanceIdManager.getIdHashFromCache());
        this._element.setAttribute("href", this._url.toString());
    }

    /**
     * @param {string} stringUrl
     * @private
     */
    __getUrlFromString(stringUrl) {

        if (!this._isUrlSupported()) {
            return false;
        }

        let url;

        try {
            url = new URL(stringUrl);
        } catch {
            return false;
        }

        if (!url.hostname) {
            return false;
        }

        return url;
    }

    /**
     * @returns {boolean}
     * @private
     */
    _isUrlSupported() {
        return typeof URL === "function";
    }
}
