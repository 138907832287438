var _a;
import ContributionButton from "./ContributionButton";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { getFeatureByName } from "../Feature";
function getPropsFromBrazeFeature(featureName) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const feature = (_a = window.ngBraze) === null || _a === void 0 ? void 0 : _a.getFeatureFlag(featureName);
    if (!feature || !(feature === null || feature === void 0 ? void 0 : feature.enabled)) {
        return null;
    }
    return {
        button_text: (_c = (_b = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _b === void 0 ? void 0 : _b.button_text) === null || _c === void 0 ? void 0 : _c.value.toString(),
        button_icon: !!((_e = (_d = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _d === void 0 ? void 0 : _d.button_icon) === null || _e === void 0 ? void 0 : _e.value),
        contribution_amount: Number((_g = (_f = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _f === void 0 ? void 0 : _f.contribution_amount) === null || _g === void 0 ? void 0 : _g.value),
        contribution_type: (_j = (_h = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _h === void 0 ? void 0 : _h.contribution_type) === null || _j === void 0 ? void 0 : _j.value.toString(),
        suptitle: (_l = (_k = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _k === void 0 ? void 0 : _k.suptitle) === null || _l === void 0 ? void 0 : _l.value.toString(),
        suptitle_bold: !!((_o = (_m = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _m === void 0 ? void 0 : _m.suptitle_bold) === null || _o === void 0 ? void 0 : _o.value),
        utm_campaign: (_q = (_p = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _p === void 0 ? void 0 : _p.utm_campaign) === null || _q === void 0 ? void 0 : _q.value.toString(),
        utm_source: (_s = (_r = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _r === void 0 ? void 0 : _r.utm_source) === null || _s === void 0 ? void 0 : _s.value.toString(),
        utm_medium: (_u = (_t = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _t === void 0 ? void 0 : _t.utm_medium) === null || _u === void 0 ? void 0 : _u.value.toString(),
        utm_content: (_w = (_v = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _v === void 0 ? void 0 : _v.utm_content) === null || _w === void 0 ? void 0 : _w.value.toString(),
    };
}
const featureName = "contribution_button";
const props = Object.assign(Object.assign({ utm_medium: `boutonSection${encodeURIComponent((_a = window.CONTRIBUTION_NAVIGATION_ITEM_NAME) !== null && _a !== void 0 ? _a : "")}` }, getPropsFromBrazeFeature(featureName)), getFeatureByName(featureName));
ReactDOM.createRoot(document.getElementById("contributionButtonRoot")).render(React.createElement(React.StrictMode, null,
    React.createElement(ContributionButton, Object.assign({}, props))));
