var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * @description
 *     This will work for GoogleChrome, Edge, Brave & Firefox
 *     Did not find a way for Safari sorry : (
 */
export function getCpuArchitecture() {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield getCpuArchitectureUsingUserAgentData()) ||
            getCpuArchitectureUsingGPU() ||
            null;
    });
}
/**
 * @description
 *     This works for GoogleChrome, Edge, Brave.
 *     But not Firefox & Safari
 */
function getCpuArchitectureUsingUserAgentData() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!window.navigator.userAgentData || !window.navigator.userAgentData.getHighEntropyValues) {
            return null;
        }
        try {
            const highEntropyValues = yield window.navigator.userAgentData.getHighEntropyValues(["architecture"]);
            return highEntropyValues.architecture;
        }
        catch (_a) {
            return null;
        }
    });
}
/**
 * @description
 *     WEBGL_debug_renderer_info is flagged as deprecated in firefox.
 *     But it's the only way in firefox to find out the architecture.
 *     So this is used as fallback for getCpuArchitectureUsingUserAgentData
 */
function getCpuArchitectureUsingGPU() {
    try {
        const w = document.createElement("canvas").getContext("webgl");
        const d = w.getExtension("WEBGL_debug_renderer_info");
        const g = d && w.getParameter(d.UNMASKED_RENDERER_WEBGL) || "";
        if (g.match(/Apple/) && !g.match(/Apple GPU/)) {
            return "arm";
        }
        return null;
    }
    catch (_a) {
        return null;
    }
}
